<template>
  <label :for="id">
    <input
        type="checkbox"
        :value="modelValue"
        :id=id
        :required="required"
        :disabled="disabled"
        :checked="modelValue"
        @change="onChanged"
    >
    <slot></slot>
  </label>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  name: "CheckboxComponent",

  emits: ['update:modelValue'],

  props: {
    modelValue: Boolean,
    id: String,
    required: {
      type: Boolean,
      default: false
    },
    disabled: Boolean,

  },

  setup(props, { emit }) {
    function onChanged(e) {
      emit('update:modelValue', e.target.checked);
    }

    return {
      onChanged
    }
  }
})
</script>

<style scoped>
label{
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  cursor: pointer;
}

input[type=checkbox] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 20px;
  height: 20px;
  background-color: transparent;
  border: 1px solid #BDBDBD;
  border-radius: 4px;
  transition: all .3s ease
}

input[type=checkbox]:checked {
  background-color: var(--primary);
  border: 1px solid var(--primary)
}

input:checked:before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/icons/new/action/check.svg");
  background-size: 100%
}


</style>