<template>
  <article class="carta__card" style="background-image: url({{category.img}})" data-aos="fade-up">
    <div class="carta__nombre">
      <h3>{{category.name}}</h3>
    </div>
    <div class="carta__hover">
      <h3>{{category.name}}</h3>
      <ButtonSecundary>{{ $t('buttons.txt_ordena_ahora') }}</ButtonSecundary>
    </div>
  </article>
</template>

<script>
import ButtonSecundary from "@/components/new/buttons/ButtonSecundary.vue";

export default {
  name: 'CategoriasCarta',
  components: {ButtonSecundary},
  props: ['category'],
}

</script>

<style scoped>
  .carta__card{
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 150px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden;
  }

  .carta__card h3{
    color: var(--text-white);
    font-family: var(--headings-font-family);
    font-size: 18px;
  }

  .carta__nombre{
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    padding: 10px 20px;
    box-shadow: 0px 0px 400px rgba(205, 116, 68, 1) inset;
    opacity: 1;
    transition: opacity .3s linear;
  }

  .carta__nombre h3{ text-align: left; }

  .carta__hover{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transform: translateY(80%);
    width: 100%;
    height: 100%;
    opacity: 0;
    box-shadow: 0px 0px 400px #CD7444 inset;
    transition: transform .3s linear;
  }

  .carta__card:hover{ flex-direction: column-reverse; }

  .carta__card:hover .carta__nombre{
    top: -100%;
    opacity: 0;
  }

  .carta__card:hover .carta__hover{
    opacity: 1;
    transform: translateY(0%);
  }

  .carta__card:not(:hover) .carta__hover {
    transform: translateY(100%);
    opacity: 1;
  }

    @media only screen and (min-width: 390px){
    .carta__card{ height: 180px; }
  }

  @media only screen and (min-width: 425px){
    .carta__card{ height: 250px; }
  }

  @media only screen and (min-width: 768px){
    .carta__card{ width: 48%; }

    .carta__card h3{ font-size: 20px; }
  }

  @media only screen and (min-width: 1024px){
    .carta__card{ width: 425px; }
  }

  @media only screen and (min-width: 1440px){
    .carta__card h3{ font-size: 24px; }
  }
</style>