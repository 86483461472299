<template>
  <button :disabled="disabled || loading">
    <SpinnerComponent
        :spinner-color="'var(--text-white)'"
        :type="'small'"
        v-if="loading"
    >
    </SpinnerComponent>
    <slot></slot>
  </button>
</template>

<script>
import SpinnerComponent from "@/components/new/buttons/SpinnerComponent";
export default {
  name: 'ButtonDanger',
  components: {SpinnerComponent},
  props: ['disabled', 'loading'],
};
</script>

<style scoped>
  button{
    background-color: var(--error-800);
    border: 1px solid var(--error-800);
    color: var(--text-white);
  }

  button:hover{
    background-color: var(--error-700);
    border: 1px solid var(--error-700);
  }

  button:active{
    background-color: var(--error);
    border: 1px solid var(--error);
  }

  button[disabled]{
    background-color: var(--error-100);
    border: 1px solid var(--error-100);
    cursor: not-allowed;
  }

  button[disabled]:hover{ box-shadow: none; }
</style>