<template>
  <div class="perfil_mis_beneficios_historial">
    <div
        class="item"
        v-for="(item,index) in history"
        :key="index"
        :style="index%2===0?'':'background:#FFFCEC'"
    >
      <div class="body">
        <p
            class="tittle"
            v-show="item.type_discount==='1'"
        >{{ $t('perfilmisbeneficioshistorial.txt_cupon') }} {{item.amount_discount}}% {{ $t('perfilmisbeneficioshistorial.txt_de_descuento') }}</p>
        <p
            class="tittle"
            v-show="item.type_discount==='2'"
        >{{ $t('perfilmisbeneficioshistorial.txt_cupon_delivery_gratis') }}</p>
        <p
            class="tittle"
            v-show="item.type_discount==='3'"
        >{{ $t('perfilmisbeneficioshistorial.txt_cupon') }} S/{{item.amount_discount}} {{ $t('perfilmisbeneficioshistorial.txt_de_descuento') }}</p>
        <p
            class="tittle"
            v-show="item.type_discount==='0' || item.type_discount==='4' || item.type_discount==='5'"
        >{{ $t('perfilmisbeneficioshistorial.txt_cupon') }} S/{{item.amount_discount}} {{ $t('perfilmisbeneficioshistorial.txt_de_descuento') }}</p>
        <p class="date">{{getFecha(item.date_expired)}}</p>
      </div>
      <span>
        {{item.points}} {{ $t('perfilmisbeneficioshistorial.txt_puntos') }}
      </span>
    </div>
    <div class="loading" v-show="loading" >
      <p>{{ $t('forgotpassword.txt_cargando_dat') }}</p>
    </div>
    <div class="loading" v-show="!loading&&history.length==0">
      <p>{{ $t('perfilmisbeneficioshistorial.txt_sin_historial') }}</p>
    </div>
  </div>
</template>

<script>
// import CuponServices from "@/services/cupon-services"
import moment from "moment";

export default {
  data () {
    return {
      type_discount: {
        0: this.$t('perfilmisbeneficioshistorial.txt_monto'),
        1: this.$t('perfilmisbeneficioshistorial.txt_porcentaje'),
        2: this.$t('perfilmisbeneficioshistorial.txt_delivery'),
        3: this.$t('perfilmisbeneficioshistorial.txt_delivery'),
        4: this.$t('perfilmisbeneficioshistorial.txt_descuento_fijo'),
        5: this.$t('perfilmisbeneficioshistorial.txt_descuento_porcentual')
      },
      history: [
      ],
      loading: false,
    }
  },
  async created () {
    try {
      this.loading=true
      // let response = await CuponServices.listaDeMisCupones()
      // if (response.data.success) {
      //   this.history = response.data.data
      // }
      this.loading = false
    } catch (error) {
      console.log(error)
    } finally{
      this.loading=false
    }
  },
  methods: {
    getFecha (fecha) {
      return moment(fecha).locale('es').format("DD/MM/YYYY, hh:mm a");
    }
  }
}
</script>
<style scoped>
.loading{
  text-align: center;
  font-size: 30px;
  align-items: center;
  min-height: 100px;
}

.loading p{
  margin-top: 20px;
  font-size: 28px;
  font-weight: var(--regular);
}
</style>